import {Component} from 'react';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import buildFunStuffPictureObj from './objects/build_fun_stuff_picture_obj';
import { getHomePicture} from './objects/get_home_picture';
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';


class GameChoicePictures extends Component {
        constructor(props) {
        super(props); 
        this.gameInfo = this.props.gameInfo();
        this.state={
            headers: setHeadersForFetch(),
            jobChoiceLoaded: false,
            homeChoiceLoaded: false,
            carChoiceLoaded: false,
            environment: Config.dataURL.currentEnv,
        };  
       
    }

    getImageURLs(){
          this.getJobPictureInfo();
          this.getHomePictureInfo();
          this.getCarPictureInfo();
          this.getFunStuffPictureInfo();
          this.setPictureInfo();
    }

    getJobPictureInfo(){     
         this.jobPictureImg = './assets/Jobs/' + this.jobChoiceData.pictureImg;
         this.jobBackgroundImg = "./assets/jobBackground/" + this.jobChoiceData.backgroundImg;
         this.jobName = this.jobChoiceData.jobName;
         this.jobLevel2Title = this.jobChoiceData.level2Title;
         this.jobLevel3Title = this.jobChoiceData.level3Title;
         this.jobMaxSalary = this.jobChoiceData.maxSalary;
    }

    getHomePictureInfo(){
        this.homePictureImg = './assets/' + this.homeChoiceData.directory + 
            "/default.jpg";
        let roommates = this.gameInfo.gameData.homeInfo.roommates;
        if (roommates === 0){  
            this.homeName = this.homeChoiceData.title + " - no roommate";
        }else{
            if (roommates === 1){
                this.homeName = this.homeChoiceData.title + " - " + roommates + " roommate";
            }else{
                this.homeName = this.homeChoiceData.title + " - " + roommates + " roommates";
            }
        }
        this.livingRoomImg = getHomePicture(this.homeChoiceData.directory, 
            this.homeChoiceData.additionalPhotosArr, "Living Room");
    }

    getCarPictureInfo(){
          this.carPictureImg = "./assets/" + this.carChoiceData.directory;
          this.carName = this.carChoiceData.sizeName + " - " + this.gameInfo.gameData.carInfo.carYear;
    }

    getFunStuffPictureInfo(){
        this.funStuffPictureObject = {};
        if (this.gameInfo.funStuffInfo.itemArr !== undefined){  
            this.funStuffPictureObject = buildFunStuffPictureObj(this.gameInfo.funStuffInfo);
            this.funStuffPictureObject = {...this.funStuffPictureObject, funStuffInfo: true};
        }else{
            this.funStuffPictureObject = {funStuffInfo: false};
        }
     }
    
    setPictureInfo(){
        let pictureInfoObj={jobID: this.gameInfo.gameData.jobInfo.careerID, jobImg: this.jobPictureImg, 
                jobName: this.jobName, jobBackground: this.jobBackgroundImg,  
                jobLevel2: this.jobLevel2Title, jobLevel3: this.jobLevel3Title, jobMaxSalary: this.jobMaxSalary,
                homeID: this.gameInfo.gameData.homeInfo.homeID, homeImg: this.homePictureImg, 
                homeName: this.homeName, livingRoomImg: this.livingRoomImg,
                carID: this.gameInfo.gameData.carInfo.carID, carImg: this.carPictureImg, carName: this.carName,
                ...this.funStuffPictureObject};
        // the following statement is causing error doing setState in a render
        this.props.setGameInfo(pictureInfoObj, 'pictureInfo');
        // it will work to put this in sessionStorage - see comments in render section of GameBoard
        // sessionStorage.setItem("gamePictureInfo", JSON.stringify(pictureInfoObj));
    }

    setScreenSizeClasses(){
         switch (this.props.screenSize){
            case "narrow":
              this.imgClass = "left-side-img-small text-center mx-0";
              this.carImgClass ="left-side-img-car-small text-center mx-0";
              this.textClass = "very-small-text2 mx-0 px-0 font-weight-bold";
              this.blankClass = "mt-5";
              break;
            case "medium":
              this.imgClass = "left-side-img-small text-center mx-0";
              this.carImgClass ="left-side-img-car-small text-center mx-0";
              this.textClass = "very-small-text2 mx-0 px-0 font-weight-bold";
              this.blankClass = "mt-5";
              break;
            default:
              this.carImgClass = "left-side-img-car";
              this.imgClass = "left-side-img";
              this.textClass = "text-for-left-side-img ml-5";
              this.blankClass = "";
              break;
         }
    }
 
    render() { 
     
      if (this.state.jobChoiceLoaded === true && this.state.homeChoiceLoaded === true && 
          this.state.carChoiceLoaded === true && this.picturesComplete === false){
            this.gameChoicesLoaded = true;
      }   
      if (this.gameChoicesLoaded === true && this.picturesComplete === false){
            this.getImageURLs();
            this.picturesComplete = true;
      }

      this.setScreenSizeClasses();
                                          
    return <>
        {this.picturesComplete  ?
          <div className="text-center">
            <div className={this.blankClass}></div>
            <div>
                <img className={this.imgClass} src={this.gameInfo.pictureInfo.jobImg} alt={"jobImg"}/>
                <p className={this.textClass}>{this.gameInfo.pictureInfo.jobName}</p>
            </div>
            <div className="mt-5">
                <img className={this.imgClass} src={this.gameInfo.pictureInfo.homeImg} alt={"homeImg"}/>
                <p className={this.textClass}>{this.gameInfo.pictureInfo.homeName}</p>
            </div>
            <div className="mt-5">
                <img className={this.carImgClass} src={this.gameInfo.pictureInfo.carImg} alt={"carImg"}/>
                <p className={this.textClass}>{this.gameInfo.pictureInfo.carName}</p>
            </div>
          </div>
        :
            <div></div>
        }
    </>
  }

  componentDidMount(){
    this.gameChoicesLoaded = false;
    this.picturesComplete = false;
    this.subProcess = "gameChoicePictures";
    this.carChoiceData = null;
    this.homeChoiceData = null;
    this.jobChoiceData = null;
    this.loadJob();
    this.loadHome();
    this.loadCar();
  }

  loadJob(){
        const funcName = "jobInfo";
        let jobID = this.gameInfo.gameData.jobInfo.careerID;
        const urlParm = "?jobId=" + jobID;
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,  
            {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode === 200){
                        this.jobChoiceData = output.body;
                        this.setState({jobChoiceLoaded: true});
                    }else{
                        const errorObj={function: funcName, subProcess: this.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                });
            };
        });
  }

  loadHome(){
    const funcName = "homeInfo";
    let homeID = this.gameInfo.gameData.homeInfo.homeID;
    const urlParm = "?homeId=" + homeID;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,  
        {method:'GET', headers: this.state.headers})
    .then(response => {
        if (response.ok){
            response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode === 200){
                    this.homeChoiceData = output.body;
                    this.setState({homeChoiceLoaded: true});
                }else{
                    const errorObj={function: funcName, subProcess: this.subProcess, 
                        status: statusCode, message: output.body.message};
                    this.props.processError(errorObj);
                }
            });
        };
    });
  }
   
  loadCar(){
    const funcName = "carInfo";
    let carID = this.gameInfo.gameData.carInfo.carID;
    const urlParm = "?carId=" + carID;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,  
        {method:'GET', headers: this.state.headers})
    .then(response => {
        if (response.ok){
            response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode === 200){
                    this.carChoiceData = output.body;
                    this.setState({carChoiceLoaded: true});
                }else{
                    const errorObj={function: funcName, subProcess: this.subProcess, 
                        status: statusCode, message: output.body.message};
                    this.props.processError(errorObj);
                }
            });
        };
    });
  }


}
 
export default GameChoicePictures;