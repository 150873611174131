import {Component} from 'react';
import "./ForParents/forParents.css";
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';
import MoneyTrain_Video_Discussion_Topics from './ForParents/Video_Presentations_Discussion_Guide.pdf'
import VideoViewerModal from './video_viewer_modal';


class DiscussionTopics extends Component {
    constructor(props) {
        super(props);
        this.state={
           modalOpen: false,
          
        };  
    }
    
    render() {
        this.tableElementClass = "col text-center";
        let screenParms = setScreenSizeParameters(this.props.screenSize, "forParents");
        this.className = screenParms.columnClass;
                                   
    return <>
           <div className={this.className}>
                <div className="card mt-3 mr-5 rounded-corners-lg height-max">
                    <button className="pages-back-button ml-4 mt-4" 
                        onClick ={()=> this.props.goBack()}>
                                Back
                    </button>
                    <h1 className="mt-0 p-0 text-center">Video Presentations</h1>
                    {this.props.playerRole === "teacher" ?
                        <h3 className="text-center">Participation Videos</h3>
                    :
                        null
                    }
                    <div className="mx-4 mt-2">
                          This is a list of videos that cover important Money Management concepts.
                          These are intended to be viewed by players (students). They
                          reinforce content and supplement the MoneyTrain game.  These videos include 
                          sound so make sure your microphone is not muted.
                    </div>
                    {this.props.playerRole === "teacher" ?
                        <span className="mt-2">
                            <div class="row">
                                <div className={this.tableElementClass}>
                                    <button className="btn-group-medium mt-2" 
                                        onClick ={()=> this.topicSelected("participationVideo")}>
                                            What is a Participation Video? 
                                            <div className="small-text">(1/2 minute)</div>
                                    </button>
                                </div>
                                <div className={this.tableElementClass}>
                                    <button className="btn-group-medium mt-2" 
                                        onClick ={()=> this.topicSelected("introduction")}>
                                            Before You Play 
                                            <div className="small-text">(5 minutes)</div>
                                    </button>
                                </div>
                                <div className={this.tableElementClass}>
                                    <button className="btn-group-medium mt-2" 
                                        onClick ={()=> this.topicSelected("jobChoiceParticipation")}>
                                            Job Choice
                                        <div className="small-text">(7 minutes)</div>
                                    </button>
                                </div>
                            </div>
                        </span>
                    :
                        <span>
                            <div class="row">
                                <div className={this.tableElementClass}>
                                    <button className="btn-group-medium mt-2" 
                                        onClick ={()=> this.topicSelected("introduction")}>
                                            Before You Play 
                                            <div className="small-text">(5 minutes)</div>
                                    </button>
                                </div>
                                <div className={this.tableElementClass}>
                                    <button className="btn-group-medium mt-2" 
                                        onClick ={()=> this.topicSelected("theBasics")}>
                                                The Basics 
                                                <div className="small-text">(Not Yet Available)</div>
                                        </button>
                                    </div>
                                    <div className={this.tableElementClass}>
                                        <button className="btn-group-medium mt-2" 
                                            onClick ={()=> this.topicSelected("needsVsWants")}>
                                                 Needs vs. Wants 
                                                <div className="small-text">(7 minutes)</div>
                                        </button>
                                    </div>
                                </div>  
                                <div className="row mt-2">
                                <div className={this.tableElementClass}>
                                    <button className="btn-group-medium mt-3" 
                                        onClick ={()=> this.topicSelected("jobChoice")}>
                                            Job Choice 
                                            <div className="small-text">(6 minutes)</div>
                                    </button>
                                </div>
                                <div className={this.tableElementClass}>
                                    <button className="btn-group-medium mt-3" 
                                        onClick ={()=> this.topicSelected("homeChoice")}>
                                            Home Choice
                                        <div className="small-text"> (6 minutes)</div>
                                    </button>
                                </div>
                                <div className={this.tableElementClass}>
                                    <button className="btn-group-medium mt-3" 
                                        onClick ={()=> this.topicSelected("carChoice")}>
                                            Car Choice
                                            <div className="small-text"> (6 minutes)</div>
                                    </button>
                                </div>
                            </div>
                        </span>
                    }
                      
                    {this.props.playerRole === "parent"  || this.props.playerRole === "teacher" ?
                        <div className="mt-4">
                           <div className="mt-4 text-center">
                                <a className="decorative light-blue-color text-pointer" 
                                    href={MoneyTrain_Video_Discussion_Topics} target="_blank" 
                                    rel="noreferrer">
                                        View Video Presentations Discussion Guide (PDF)
                                </a>
                            </div>
                        </div>
                    :
                        null
                    }               
                                                 
                    {this.state.modalOpen ?
                        <div className="video-display-size" >
                            <VideoViewerModal
                                topicName={this.topicName}
                                show={this.state.modalOpen}
                                onClose={() => this.turnVideoOff()} 
                            />
                        </div>
                    :
                        null
                    }

                                      
                </div>        
            </div>
        </>
    }
    
    topicSelected(topicName){
        this.topicName = topicName;
        this.setState({modalOpen: true});
    }

    turnVideoOff(){
        this.powerPointVideo = "";
        this.setState({modalOpen: false});
    }


}

export default DiscussionTopics;