import friendVolunteerActivityDetailDialogDataset from '../DatasetsDialog/friend_volunteer_activity_detail_dialog';
import friendVacationActivityDetailDialogDataset from '../DatasetsDialog/friend_vacation_activity_detail_dialog';
import friendHaveFunActivityDetailDialogDataset from '../DatasetsDialog/friend_haveFun_activity_detail_dialog';
import shuffleArray from '../../../objects/CommonUse/shuffleArray';

export const retrieveDetailActivityDialog = (activityCategory, activityPreference, activityName, 
            nbrDetailComments) => {
    let dialogDetailComments = "";
    switch (activityCategory){
        case "Volunteer":
            dialogDetailComments = friendVolunteerActivityDetailDialogDataset;
            break;
        case "HaveFun":
            dialogDetailComments = friendHaveFunActivityDetailDialogDataset
            break;
        case "Vacation":
            dialogDetailComments = friendVacationActivityDetailDialogDataset;
            break;
        default:
            break;
    }
    
    let commentArr = [];
    const defaultCommentObj = dialogDetailComments.find(elem => elem.activityPreference + "default" === 
        activityPreference + "default");  
    let detailCommentObj = dialogDetailComments.find(elem => elem.activityPreference + elem.activityName === 
        activityPreference + activityName);  
    let possibleCommentArr = defaultCommentObj.comments;
    if (detailCommentObj !== undefined){
        // combine default comments and detailed comments
        const detailComments = detailCommentObj.comments;
        for (let i=0; i<detailComments.length; i++){
            possibleCommentArr.push(detailComments[i]);
        }
    }
    let commentTableShuffled = shuffleArray(possibleCommentArr);
    let nbrComments = nbrDetailComments;
    if (commentTableShuffled.length < nbrDetailComments){
        nbrComments = commentTableShuffled.length;
    }
    for (let j=0; j<nbrComments; j++){
        commentArr.push(commentTableShuffled[j]);
    }
    return commentArr;
}

export default retrieveDetailActivityDialog;

