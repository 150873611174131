const friendVacationActivityDialogDataset = [
        {activityPreference: "2", activityName: "default",
                comments: ["This place reminds me a lot of other places I've been.", 
                        "This is sort of cool!  But I think the pictures I've seen make it seem better than it is.", 
                        "Well, I probably could have skipped seeing this one.  I mean, it's OK but not amazing or anything.",
                        "What's next on your list?  Perhaps we can check several off the list today.",
                        "I'm glad we're headed home soon.  It'll be good to sleep in my own bed."
                        ]},
        {activityPreference: "3", activityName: "default",
                comments: ["This is really cool!  What are we going to go see next?", 
                        "I really like it here.  Maybe we can hang out for a bit before moving on.", 
                        "This is really great, thanks for asking me to come along.",
                        "I've seen pictures of this before.  But is it so cool to see it in person!",
                        "I'm really glad that I came along with you.  This is fun!",
                        "I can't believe this is almost over.  I think I'll have to come back sometime."
                ]},
        {activityPreference: "4", activityName: "default",
                comments: ["There is so much to see.  It's hard to know where to begin.",
                        "This is a great day!  I am so excited to be here.",
                        "I just want to stand here and take it all in for awhile. It looks amazing!",
                        "Have you ever seen anything like this?  It looks so different than where I come from.",
                        "I've always wanted to come here.  I've seen pictures before, but it is much better to see this in person!",
                        "My brother was so jealous when I told him I was coming here. I'm so glad that I got to see it!"
                ]},

        // "Paris"
        {activityPreference: "2", activityName: "Paris",
                comments: ["I've been to Paris so many times with my parents.  It just isn't very exciting anymore.",
                          "Do you really want to climb the steps of the Eiffel Tower?  It is a long line and not worth the effort.",
                          
                ]},
        {activityPreference: "3", activityName: "Paris",
                comments: ["I've seen so many pictures of the 'Arche de Triumph' and the 'Eiffel Tower'.  It is " +
                        "so much better to see them in person.", 
                        "I can't believe I'm in Paris... I mean really, it is so cool!",
            
        ]},
        {activityPreference: "4", activityName: "Paris",
                comments: ["The food here is amazing.  I've always loved French cooking and the pastries... yum!",
                        "Everything we've seen in Paris is so cool.  It is even better than I expected.",
                        "I really like being in Paris.  It lets me practice speaking French."
        ]},

        // "SanDiego"
        {activityPreference: "2", activityName: "SanDiego",
                comments: ["I know you thought it was a fine idea to have a picnic on the beach, but there was " +
                                "so much sand in my food.  Yuck!",
                          "Do we really have to go parasailing? I'm kind of afraid of being up so high.",
                
                          
                ]},
        {activityPreference: "3", activityName: "SanDiego",
                comments: ["I love this place, the beach, the ocean, the zoo.  It's all amazing.",
                        
        ]},
        {activityPreference: "4", activityName: "SanDiego",
                comments: ["The Midway aircraft carrier is amazing.  It is so big.  Can you imagine being on board?",
               
        ]},

        // "Yellowstone"
        {activityPreference: "2", activityName: "Yellowstone",
                comments: ["I've decided that I hate camping.  I can't sleep on the ground, it's way too hard.",
                        "So there are a lot of geysers and hot pools here... What's the big deal with them?",
                  
        ]},
        {activityPreference: "3", activityName: "Yellowstone",
                comments: ["I've seen a lot of wild life on this trip.  Even saw a big bear.  Very cool!",                      
        ]},
        {activityPreference: "4", activityName: "Yellowstone",
                comments: ["I love being outdoors... fishing, hiking, and seeing all of this cool stuff.",                      "out on the lake.",        
        ]},

        // "WashingtonDC"
        {activityPreference: "2", activityName: "WashingtonDC",
                comments: ["So this is it, our nation's capital. Somehow I thought this would be bigger..." +  
                          "more impressive.",
        ]},
        {activityPreference: "3", activityName: "WashingtonDC",
                comments: ["I love walking on the Washington Mall " +
                        "past all of these famous memorials.",                      
        ]},
        {activityPreference: "4", activityName: "WashingtonDC",
                comments: ["I've seen pictures of the capital buildings, but it's way better to " +
                        "see them in person!",        
        ]},

]
 
export default friendVacationActivityDialogDataset;