import "./GameViews/gameViews.css";
import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ConfigFile from "../../datasets/config_file";
import {doubleToStringMonthly} from '../../objects/CommonUse/double_functions';
import { determineCollegeLoanObj } from '../../objects/AssetsExpenses/determine_college_loan_obj';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import { rollForwardDate} from './objects/roll_forward_date_function';
import { removeConditionsTable } from './objects/remove_Conditions_Table_function';
import { setupConditionsUpdate } from './objects/set_up_conditions_update_function';
import {determineYearsOfCollege} from './objects/determine_years_of_college';
import {calcNbrMonthsBtwnDates} from '../../objects/CommonUse/nbr_months_btwn_dates_calc';
import { createSalaryAsset } from '../../objects/AssetsExpenses/create_salary_asset';
import { createTaxAmtExpense } from '../../objects/AssetsExpenses/create_tax_amt_expense';
import { createHealthInsExpense } from '../../objects/AssetsExpenses/create_health_ins_expense';
import {calculateJobSalary} from '../../objects/AssetsExpenses/calculate_job_salary';
import StepStoneText from "./text/step_stone_text";
import { replaceTextInString } from "../../objects/CommonUse/replace_text_in_string";
import TwoColumnTableCard from "./two_column_table_card";
import createMoodPointArr from "../shared-components/objects/create_mood_point_arr";
import determineMoodPoints from "../../objects/CommonUse/determine_mood_points";
import WaitTimeSpinner from "../shared-components/wait_time_spinner";
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';
import { reformatDateToDisplay } from "../../objects/CommonUse/reformatDateToDisplay_function";

class CareerChangeModal extends Component {
  constructor(props) {
    super(props);
    this.gameInfo = this.props.gameInfo();
    this.state={
         headers: setHeadersForFetch(),
         careerData: null,
         loadingCareerData: true,
         careerDataSw: false,
         gameData: null,
         gameDataSw: false,
         currentCareerId: null,
         currentCareerName: "",
         relatedCareers: [],
         newCareerID: "",
         newCareerName: "",
         newSalary: 0,
         degreeType: "",
         degreeSpecialty: "",
         openFriendModal: false,
         collegeLoanTable: [],
         pendingJobObj: {},
         newExpenseTable: {},
         newAssetTable: {},
         newLoanTable: {},
         newConditionsTable: {},
         totalMoodPoints: {},
         requiresCollege: false,
         totalNbrYearsCollege: 0,
         subProcess: "careerChangeModal",
         environment: Config.dataURL.currentEnv,
    }; 
    this.getCurrentCareerData();   
  }

  determineCareerChangeAmts(){
      this.getNewCareerData();
      this.getGameData();
           
      if (this.state.currentCareerId == this.state.newCareerID)  {  
            this.modalType = "SameCareer";
      }else{ 
            this.modalType = "NewCareerInfo"
            this.createNewTableObjects();
      }
  }
  
  getNewCareerData() {
      this.state.newCareerID = sessionStorage.getItem("careerChoice");
      this.state.newCareerName = sessionStorage.getItem("careerName");
      this.state.newSalary = sessionStorage.getItem("salary");
      this.newAnnualSalary = this.state.newSalary * 12;
      this.newNbrYearsCollege = JSON.parse(JSON.stringify(sessionStorage.getItem("jobNbrYearsCollege")));
      this.newNbrYearsCollege = +this.newNbrYearsCollege;
      this.state.degreeType = sessionStorage.getItem("jobDegreeType");
      this.state.degreeSpecialty = sessionStorage.getItem("jobDegreeSpecialty");
      this.state.relatedCareers = JSON.parse(sessionStorage.getItem("careerRelatedCareers"));
      this.newHealthInsInd = sessionStorage.getItem("jobHealthInsInd");
      this.newJobRank = sessionStorage.getItem("jobRank");
  }

  getGameData() {
      this.state.gameData = this.gameInfo.gameData;
      this.loanTable = JSON.parse(JSON.stringify(this.state.gameData.loanTable));
      this.currentGameDate = this.state.gameData.currentGameDate; 
      this.assetTable = JSON.parse(JSON.stringify(this.state.gameData.assetTable));
      this.expenseTable = JSON.parse(JSON.stringify(this.state.gameData.expenseTable));
      this.state.newConditionsTable = JSON.parse(JSON.stringify(this.gameInfo.gameData.conditionsTable));
      this.totalGameNbrYearsCollege = +this.state.gameData.jobInfo.nbrYearsCollege;
      this.startGameDate = this.state.gameData.startGameDate;
      this.state.gameDataSw = true;
      this.salary = calculateJobSalary(this.assetTable, this.currentGameDate, true);
      this.currentAnnualSalary = +this.salary * 12;
      this.currentHealthInsInd = this.state.gameData.jobInfo.healthInsInd;
  }

  createNewTableObjects() {
      this.determineIfStepStoneJob();
      this.calculateYearsOfCollege();
      this.createConditionTableObject();
      this.determineJobChangeMoodPoints();
      if (this.adjustedNbrYearsCollege == 0){
        this.createNewAssetTableObject();  
        this.createNewExpenseTableObject();
        this.state.newLoanTable = this.loanTable;
      }else{
        this.createNewLoanTableObject();
        this.createPendingObj();
      }
  }
    
  determineIfStepStoneJob(){
    this.determineNbrMonthsInCurrentCareer();
    this.stepStoneCareerName = "";
    this.stepStoneType = "";
    for (let i=0; i<this.state.relatedCareers.length; i++){
         let relatedCareers = this.state.relatedCareers[i];
         if (relatedCareers.stepStone == true){  
                if (this.state.currentCareerName != relatedCareers.jobName &&
                    this.currentNbrYearsCollege != 0){
                      this.stepStoneType = "College"
                      this.stepStoneCareerName = relatedCareers.jobName;
                      this.replaceText();
                }
                if (this.state.currentCareerName == relatedCareers.jobName &&
                    this.careerNbrMonths < 24){ 
                       this.stepStoneType = "JobExperiencePartial"
                       this.stepStoneCareerName = relatedCareers.jobName;
                       this.replaceText();
                }
                if (this.state.currentCareerName != relatedCareers.jobName &&
                  this.currentNbrYearsCollege == 0){ 
                     this.stepStoneType = "JobExperienceNone"
                     this.stepStoneCareerName = relatedCareers.jobName;
                     this.replaceText();
                }
         }
    } 
  }

  replaceText(){
      this.expandStepStoneText = false;
      let stepStoneArr = StepStoneText;
      for (let i=0; i<stepStoneArr.length; i++){
          if (stepStoneArr[i].type == this.stepStoneType){
              let shortStepStoneText = stepStoneArr[i].shortText;
              this.stepStoneFullText = stepStoneArr[i].text
              let replaceObj = {careerName: this.state.newCareerName,
                  stepCareerName: this.stepStoneCareerName,
                  nbrMonths: this.careerNbrMonths};
              this.shortStepStoneText = replaceTextInString(shortStepStoneText, replaceObj);
              break;  
          }
       }
  }
    
  determineNbrMonthsInCurrentCareer(){
      //  determine how many months the individual has been working in their current career
      this.careerNbrMonths = 0;
      for (let i=0; i<this.assetTable.length; i++){
        if (this.assetTable[i].assetItem == "Job Salary" &&
            this.assetTable[i].assetNbrMonths == "-1"){
              let careerCheck = this.assetTable[i].assetDesc.indexOf(this.state.currentCareerName);
              if (careerCheck != -1){
                  let careerStartDate = this.assetTable[i].assetStartDate;
                  this.careerNbrMonths = calcNbrMonthsBtwnDates(careerStartDate, this.currentGameDate);
              }
        }
      }
 }      
    
  calculateYearsOfCollege(){  
      this.reducedNbrYearsCollege = 0;
      if (this.newNbrYearsCollege > 0){
        if (this.gameInfo.gameData.jobInfo.prevJobArr != undefined){
          let prevJobObj = undefined;
          let startIndx = this.gameInfo.gameData.jobInfo.prevJobArr.length - 1;
          for (let i=startIndx; i>=0; i--){
              let prevJobArr = this.gameInfo.gameData.jobInfo.prevJobArr[i];
              if (prevJobArr.previousJobName == this.state.newCareerName){ 
                   prevJobObj = prevJobArr;
                   break;
              }
          }  
          if (prevJobObj != undefined){
              const nbrMonthsSinceJob = +prevJobObj.gameMonth - +this.gameInfo.gameData.gameNbrMonths;
              const nbrYearsSinceJob = +nbrMonthsSinceJob / 12;
              if (nbrYearsSinceJob > 5){
                 this.reducedNbrYearsCollege = (+this.newNbrYearsCollege * .50);
              }else{
                 if (nbrYearsSinceJob > 1){
                    this.reducedNbrYearsCollege = (+this.newNbrYearsCollege * .75);
                 }else{
                    this.reducedNbrYearsCollege = +this.newNbrYearsCollege;
                 }
              }
              if (this.reducedNbrYearsCollege < this.newNbrYearsCollege){
                  let nbrYears = nbrYearsSinceJob.toFixed(0);
                  this.prevJobText = "You had the job of " + this.state.newCareerName + " previously " +
                    "but it has been about " + nbrYears + " years since you had this job.  So you need " +
                    "to go back to college to refresh and update your knowledge."
              }
          }
        }else{
            this.reducedNbrYearsCollege = determineYearsOfCollege(this.newNbrYearsCollege,
              this.currentNbrYearsCollege, this.state.relatedCareers, this.state.currentCareerName, 
              this.careerNbrMonths, this.stepStoneType);
        }
        
      }
       
      this.adjustedNbrYearsCollege = this.newNbrYearsCollege - this.reducedNbrYearsCollege;
      // calculate the total number of years that this player will have been in college
      this.state.totalNbrYearsCollege = +this.totalGameNbrYearsCollege + this.adjustedNbrYearsCollege;
      if (this.adjustedNbrYearsCollege == 0){
          this.state.requiresCollege = false;
          this.newJobStartDate = this.currentGameDate;
          this.stepStoneType = "";
      }else{
          this.state.requiresCollege = true;
          this.collegeWhenWorking = (this.adjustedNbrYearsCollege * .5)
          this.actualNbrYearsCollege = this.adjustedNbrYearsCollege + this.collegeWhenWorking;
          let collegeNbrMonths = this.actualNbrYearsCollege * 12;
          this.newJobStartDate = rollForwardDate(this.currentGameDate, collegeNbrMonths);
          this.collegeRequiredText = "The new job you have chosen will not start " +
            "immediately.  Review the 'Expected Start Date of New Job' displayed below."
      }
  }
  
   createNewLoanTableObject() {
      this.determineTuitionPaidByEmployer()
      this.loanDesc = this.state.degreeType + "-" + this.state.degreeSpecialty 
      let newLoanObj = determineCollegeLoanObj(this.adjustedNbrYearsCollege, this.loanDesc, 
          this.currentGameDate, this.state.startGameDate, this.pctOfTuition);
      this.newTotalCollegeLoanAmt = +newLoanObj.loanTotAmt;
      this.saveTotalCollegeLoanAmt = this.newTotalCollegeLoanAmt;
      this.monthlyLoanAmt = newLoanObj.loanAmt;
      if (this.adjustedNbrYearsCollege == 0){
           this.state.newLoanTable = this.loanTable;
      }else{
           this.state.newLoanTable = [];
      }
      this.state.collegeLoanTable = [];
      var loanStartDate = this.currentGameDate;
      this.perYearCollegeAmt = this.saveTotalCollegeLoanAmt / this.actualNbrYearsCollege;
      for (let i=0; i<this.actualNbrYearsCollege; i++){
          loanStartDate = rollForwardDate(loanStartDate, 12);
          let loanDesc = this.loanDesc + "(Year " + (i+1) + ")"
          let nbrYearsRemaining = this.actualNbrYearsCollege - i;
          let loanYear = 1
          if (nbrYearsRemaining < 1){
               loanYear = nbrYearsRemaining;
          }
          let newLoanObj = determineCollegeLoanObj(loanYear, loanDesc, loanStartDate,  loanStartDate, 
           0, this.perYearCollegeAmt);
          this.newCollegeLoanAmt = +newLoanObj.loanTotAmt;
          this.newTotalCollegeLoanAmt -= this.newCollegeLoanAmt;
          this.state.collegeLoanTable.push(newLoanObj);
          this.state.newLoanTable.push(newLoanObj);
      }
   }

   determineTuitionPaidByEmployer(){
        // 50% chance that employer pays some of the college tuition amount
        this.tuitionPaidByEmployer = Math.random().toFixed(0); 
        if (this.tuitionPaidByEmployer = 1){
          this.pctOfTuition = (Math.floor(Math.random() * 5))/10;
        }else{
          this.pctOfTuition = 0;
        }
   }
  
  createConditionTableObject(){
    let conditionClasses = ["career"];
    if (this.adjustedNbrYearsCollege == 0){
        this.state.newConditionsTable = removeConditionsTable(this.gameInfo.gameData.conditionsTable, "career");
        let newGameData={jobInfo: {careerID: this.state.newCareerID}};
        let newCareerConditionsTable = 
            setupConditionsUpdate(newGameData, this.props.conditionList, conditionClasses);
        for (let i=0; i<newCareerConditionsTable.length; i++){
            this.state.newConditionsTable.push(newCareerConditionsTable[i]);
        }
    }else{
        let collegeCondition = 
            {condName: "in-college", conditionClass: "job"}
        this.state.newConditionsTable.push(collegeCondition);
    }
  }
  
  determineJobChangeMoodPoints(){
    this.startingMoodPoints = ConfigFile.gameChoice.jobChangeMood;
    let gameChoiceArr = createMoodPointArr("job");
    this.moodPointStartArr = determineMoodPoints(gameChoiceArr);
    this.state.totalMoodPoints = +this.startingMoodPoints;
    for (let i=0; i<this.moodPointStartArr.length; i++){
         this.state.totalMoodPoints += +this.moodPointStartArr[i].number;
    }
  }
    
  createNewAssetTableObject() {
    // set the number of months remaining for current job equal to the number of months
    // required for college 
    this.state.newAssetTable = createSalaryAsset(this.assetTable, this.state.newSalary, this.state.newCareerName,
           this.currentGameDate, this.startGameDate);
  }

 createNewExpenseTableObject(){
     this.state.newExpenseTable = createTaxAmtExpense(this.expenseTable, this.state.newAssetTable, 
          this.currentGameDate);   
     if (this.newHealthInsInd != this.currentHealthInsInd){
          this.expenseTable = createHealthInsExpense(this.expenseTable, this.newHealthInsInd,
              this.currentGameDate, this.startGameDate)
     }
  }

  createPendingObj(){
      let changeHealthIns = false;
      if (this.currentHealthInsInd != this.newHealthInsInd){
          changeHealthIns = true;
      }
      let jobInfoObj = {careerID: this.state.newCareerID, careerName: this.state.newCareerName, 
              newMonthlySalary: this.state.newSalary, healthInsInd: this.newHealthInsInd,
              changeHealthIns: changeHealthIns, jobRank: this.newJobRank, 
              totalNbrYearsCollege: this.totalNbrYearsCollege, nbrYearsCollege: this.adjustedNbrYearsCollege};
      let newGameData={jobInfo: {careerID: this.state.newCareerID}};
      let conditionClasses = ["career"];
      let pendingJobConditions = 
              setupConditionsUpdate(newGameData, this.props.conditionList, conditionClasses);
      this.state.pendingJobObj = {class: "career", jobStartDate: this.newJobStartDate, jobInfo: jobInfoObj,
              jobConditions: pendingJobConditions, loanTable: this.state.newLoanTable, pictureInfo: ""}
  }


  render() { 
      if (this.state.careerDataSw == true) {
          this.determineCareerChangeAmts();
      }
      if (this.modalType == "NewCareerInfo"){
          if (this.adjustedNbrYearsCollege != 0){
             this.collegeLoanList = this.state.collegeLoanTable.map((loanAmts,key) =>
              <TwoColumnTableCard key={key} infoType="date-amount" outInfo={loanAmts}  />
              );  
          }  
          this.moodPointList = this.moodPointStartArr.map((moodPoints,key) =>
            <TwoColumnTableCard key={key} infoType="label-number" outInfo={moodPoints}  />
          );
      }
             
  return <>
    {this.modalType == "SameCareer" ?
        <Modal show={this.props.show}  onHide={() => this.props.onClose()} size="med" scrollable="true"
            animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Career Change - Make Another Choice</Modal.Title> 
          </Modal.Header>
          <Modal.Body>
            <div>
                <p>The career that you have selected is the same as your current
                   career.  Please click on the 'close' button and then select
                   another option.
                </p>
              </div>
          </Modal.Body>
          <Modal.Footer>
          <Button variant="secondary"   onClick={() => this.props.onClose()}>
               Close
            </Button>
          </Modal.Footer>
          </Modal>
    :
    <div></div>
    }

    {this.modalType == "TrainVideo"  ?
        <Modal show={this.props.show}  onHide={() => this.props.onClose()} size="med" animation={false}>
        <Modal.Body>
          <span>
              <div className="text-white mt-4">xxxxxx</div>
              <div className="text-white mt-4">xxxxxx</div>
                  <WaitTimeSpinner />
              <div className="text-white mt-4">xxxxxx</div>
              <div className="text-white mt-4">xxxxxx</div>
              <div className="text-white mt-4">xxxxxx</div>
          </span>
      </Modal.Body>
      </Modal>
    :
      null  
    }

    {this.modalType == "NewCareerInfo" ?
    <Modal show={this.props.show}  onHide={() => this.props.onClose()} size="med" scrollable="true">
      <Modal.Header className="gameChoiceModalHdrFtrBackground" closeButton>
        <Modal.Title>Cost Information for Changing Careers</Modal.Title> 
      </Modal.Header>
      <Modal.Body>
        <p className="mr-2">Here is the key information about your new career selection. 
            Click the "Continue/Accept" button to confirm that this career is a good choice
            for you.</p>  
          {this.collegeRequiredText != undefined && this.collegeRequiredText !== "" ?
            <div className="mt-2 highlight"><b>Note:</b>&nbsp;&nbsp;{this.collegeRequiredText}</div>
          :
            null
          }
          {this.prevJobText != undefined && this.prevJobText != ""  ?
            <div className="mt-2"><b>Note:</b> &nbsp;&nbsp;{this.prevJobText}</div>
          :
            null
          }

          {this.stepStoneType != "" ?
              <span>
                {this.expandStepStoneText ?
                    <div>
                        <b>Note:&nbsp; </b> {this.stepStoneText}  &nbsp;&nbsp;&nbsp;
                        <Button variant="outline-info" size="sm"
                            onClick={() => this.collapseStepStone()}>
                                Collapse
                        </Button>
                    </div>
                    :
                    <div className="ml-2 mt-2">
                        <b>Note:&nbsp; </b> {this.shortStepStoneText} &nbsp;&nbsp;&nbsp; 
                        <Button variant="outline-info" size="sm"
                            onClick={() => this.expandStepStone()}>
                                Learn More
                        </Button>
                    </div>
              }
              </span>
          :
            null
          }  

          <div className="mt-4"></div>
          <table className="table table-hover">
               <tbody>
                  <tr>
                      <th className="text-left p-0">{this.state.currentCareerName}&nbsp;Salary Amount</th>
                      <td className="text-right p-0">{doubleToStringMonthly(this.currentAnnualSalary)}</td>
                  </tr>
                  <tr>
                      <th className="text-left p-0">{this.state.newCareerName}&nbsp;Starting Salary</th>
                      <td className="text-right p-0">{doubleToStringMonthly(this.newAnnualSalary)}</td>
                  </tr>
                </tbody>
            </table>
            {this.adjustedNbrYearsCollege > 0 ?
                <table className="table table-hover">
                  <tbody className="row-100">
                    <tr>
                      <th className="text-left p-0">Pct Your Employer Will Pay toward College</th>
                      <td className="text-right p-0">{this.pctOfTuition*100}%</td>
                    </tr>
                    <tr>
                       <th className="text-left p-0">College Required for New job</th>
                       <td className="text-right p-0">{+this.newNbrYearsCollege} Year(s)</td>
                    </tr>
                    <tr>
                       <th className="text-left p-0">Reduced Due To Previous College Credit</th>
                       <td className="text-right p-0">{+this.reducedNbrYearsCollege}&nbsp;Year(s)</td>
                    </tr>
                    <tr>
                      <th className="text-left p-0">Added time for college when working</th>
                      <td className="text-right p-0">{this.collegeWhenWorking} Year(s)</td>
                    </tr>
                    {this.gameInfo.gameData.currentGameDate !== this.newJobStartDate ?
                      <tr>
                        <th className="text-left p-0 ">Current Game Date</th>
                        <td className="text-right p-0">
                            {reformatDateToDisplay(this.gameInfo.gameData.currentGameDate)}</td>
                      </tr>
                    :
                      null
                    }
                    <tr>
                      <th className="text-left p-0 highlight">Expected Start Date of New Job</th>
                      <td className="text-right p-0 highlight">{reformatDateToDisplay(this.newJobStartDate)}</td>
                    </tr>
                   
                  </tbody>
                  <tbody className="row-100">
                    <tr>
                        <th className="text-left">----------------------------------------------</th>
                        <th className="text-center">-------------</th>
                    </tr>
                    <tr>
                       <th className="text-left p-0">{this.loanDesc}</th>
                       <td className="text-right p-0">{this.actualNbrYearsCollege}&nbsp;year(s)</td>
                    </tr>
                    <tr>
                       <th className="text-left">Loan Term</th>
                       <th className="text-right">Monthly</th>
                    </tr>
                          
                    {this.collegeLoanList}
                  
                  </tbody>
                </table>
             :
                null
       
              }
              <h5>Mood Point Change</h5>
              <div className="ml-2">You are so excited to be thinking about a 
                  new job.  This will add points to your mood score.
              </div>
             <table className="table table-hover">
                  <tbody className="row-100">
                    <tr>
                      <th className="text-left p-0">Description</th>
                      <th className="text-right p-0">Mood Points</th>
                    </tr>
                    <tr>
                      <th className="text-left p-0">Base Job Change Mood Points</th>
                      <th className="text-right p-0">{this.startingMoodPoints}</th>
                    </tr>
                    {this.moodPointList}
                    <tr>
                      <th className="text-left p-0">Mood Point Change</th>
                      <th className="text-right p-0">{this.state.totalMoodPoints}</th>
                    </tr>
                  </tbody>
            </table>
     
                               
       </Modal.Body>
        <Modal.Footer  className="gameChoiceModalHdrFtrBackground">
          <Button variant="secondary"   onClick={() => this.props.onClose()}>
              Close
          </Button>
          <Button variant="primary"  onClick={() => this.confirmCareerChange()}>
              Continue/Accept
          </Button>
        </Modal.Footer>
      </Modal>
      : 
        null
      }
    </>
  }

  componentDidMount(){
     this._isMounted = true;
  }

  componentWillUnmount(){
    this._isMounted = false;
  }
  
  expandStepStone(){
    this.expandStepStoneText = true;
    let replaceObj = {careerName: this.state.newCareerName,
      stepCareerName: this.stepStoneCareerName,
      nbrMonths: this.careerNbrMonths};
    this.stepStoneText = replaceTextInString(this.stepStoneFullText, replaceObj);  
  }

  collapseStepStone(){
      this.expandStepStoneText = false;
  }

  confirmCareerChange(){
      const careerObj = {requiresCollege: this.state.requiresCollege, nbrYearsCollege: this.state.totalNbrYearsCollege,
        moodPoints: this.state.totalMoodPoints, conditionsTable: this.state.newConditionsTable,
        assetTable: this.state.newAssetTable, expenseTable: this.state.newExpenseTable, loanTable: this.state.newLoanTable,
        pendingJobObj: this.state.pendingJobObj};
      this.props.onConfirmCareerChange(careerObj);
  }

  getCurrentCareerData() {
      this.modalType = "TrainVideo";
      this.state.currentCareerId = this.gameInfo.gameData.jobInfo.careerID; 
      const funcName = "jobInfo";
      const urlParm = "?jobId=" + this.state.currentCareerId;
      const uri = buildUrl(this.state.environment, funcName, urlParm);
      fetch (uri,  
          {method:'GET', headers: this.state.headers})
          .then(response => {
            if (response.ok){
                response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode == 200){
                    this.state.careerData = output.body;
                    this.state.currentCareerName = this.state.careerData.jobName;
                    sessionStorage.setItem("previousJobName", this.state.currentCareerName);
                    this.currentNbrYearsCollege = this.state.careerData.nbrYearsCollege;
                    if (this._isMounted === true){
                      this.setState({careerDataSw: true});
                    }
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message};
                    this.props.processError(errorObj);
                }
            });
        }
        });
  }

 
}
export default CareerChangeModal;