import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {setHeadersForFetch} from '../../objects/CommonUse/set_headers_for_fetch';
import TimeWizardPolicyOptions from './datasets/time_wizard_options_dataset';
import {findDataObject} from '../../objects/CommonUse/find_data_object';
import getCurrentDateTime from '../../objects/CommonUse/get_current_date_time';
import DropDownOptionCard from '../shared-components/drop_down_option_card';
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';
import setModalSize from '../../objects/CommonUse/set_modal_size';
import ConfigFile from '../../datasets/config_file';

class SetupTimeWizardModal extends Component {
            constructor(props) {
              super(props);
              this.gameInfo = this.props.gameInfo();
              this.state={
                headers: setHeadersForFetch(),  
                policyOptions: TimeWizardPolicyOptions,
                updateEnabled: false,   
                step: "display",
                displayInfo: true,
                defaultClass: "font-weight-bold ",
                subProcess: "setupTimeWizardModal",
                environment: Config.dataURL.currentEnv,
              }   
        this.initializeFields();
    }
    
    initializeFields(){
        let searchArg = this.props.level + "monthsPerTurn";
        let tableObj = "";
        tableObj = this.state.policyOptions.find(elem => elem.level + elem.type === searchArg);
        this.gameMonthsOptionArr = tableObj.optionList;
        searchArg = this.props.level + "nbrGameYears";
        tableObj = this.state.policyOptions.find(elem => elem.level + elem.type === searchArg);
        this.gameYearsOptionArr = tableObj.optionList;
        this.defaultValue = "0";
        this.defaultMonthsPerTurn = "1";
        this.defaultGameNbrYears = (+ConfigFile.gameStartItems.totalNbrGameMonths / 12).toFixed(0);
        this.defaultGameNbrYears = this.defaultGameNbrYears.toString();
        this.currentTitle = "Current Time Values "; 
        if (this.gameInfo.timeWizardInfo.gameID !== undefined){
            this.currentTitle = this.currentTitle + "(Level: " + this.gameInfo.timeWizardInfo.level + ")";
        }
        this.currentLevel = this.gameInfo.funStuffInfo.level;
        const unlockedFeaturesObj = 
            this.gameInfo.gameData.unlockedFeatures.find(elem => elem.featureName === "timeWizard");
        this.newLevel = unlockedFeaturesObj.level;
    }
  
    render() {
        this.gameMonthsOptions = this.gameMonthsOptionArr.map((optionsList,index) =>
            <DropDownOptionCard options={optionsList} key={index} />
        );
        this.gameYearsOptions = this.gameYearsOptionArr.map((optionsList,index) =>
            <DropDownOptionCard options={optionsList} key={index} />
    );
        this.gutters = setModalSize(this.props.screenSize);
                        
    return <>
      
      <Modal show={this.props.show}  onHide={() => this.props.onClose("none")} size="med" scrollable="true"
            dialogClassName={this.gutters} animation={false}>
        <Modal.Header className="backgroundTopBottom" closeButton>
          <Modal.Title className="text-center">Time Wizard</Modal.Title>
        </Modal.Header>
        <Modal.Body>
             <span>
                <div className="mx-2 ">
                        With this feature, you control time in the game.  The "Number of Months Per Turn"
                        controls how quickly time moves along in the game.  The "Maximum Number of Game Years" 
                        is used to determine how many game years must pass before the game ends.  The options
                        available for each of these items changes as you reach new Time Wizard levels.
                </div>
                <span>
                    <h5 className="text-center mt-2">{this.currentTitle}</h5>
                    <div className="ml-5 row w-80">
                            <div className="col-sm-8 font-weight-bold">
                               Number of Months Per Turn:
                            </div>
                            <div className="col-sm-2 text-right">
                                {this.props.nbrMonthsPerTurn}
                            </div>
                    </div>
                    <div className="ml-5 row w-80">
                            <div className="col-sm-8 font-weight-bold">Maximum Number of Game Years:</div>
                            <div className="col-sm-2 text-right">
                                    {this.props.nbrGameYears}
                            </div>
                    </div>
                </span>
                    <span>
                        <div className="text-center">
                            ----------------------------------------------------------------------
                        </div>
                        <span> 
                            <h5 className="text-center">New Time Values (Level: {this.newLevel})</h5>   
                        </span>
                        {this.state.step !== "confirm" ?
                        <span>
                            <div className="mt-2 mx-2">
                                <label className={this.changeTypeClass} htmlFor="changeType">
                                    Change Type:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                <select className="btn btn-sm btn-outline-secondary dropdown-toggle" 
                                    id="changeType">
                                    <option value="none">No change type selected</option>
                                    <option value="restore">Reset To Game Defaults</option>
                                    <option value="change">Change Time Values</option>
                                </select>
                            </div>         
                            <div className="mt-2 mx-2">
                                <label className={this.monthsPerTurnClass} htmlFor="monthsPerTurn">
                                    Nbr of Months Per Turn? :&nbsp;&nbsp;&nbsp;</label>
                                <select className="btn btn-sm btn-outline-secondary dropdown-toggle" 
                                    id="monthsPerTurn" defaultValue={this.defaultValue}>
                                   {this.gameMonthsOptions}
                                </select>
                            </div> 
                            <div className="mt-2 mx-2">
                                <label className={this.gameNbrYearsClass} htmlFor="nbrGameYears">
                                    Nbr of Years in the Game:&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                <select className="btn btn-sm btn-outline-secondary dropdown-toggle" 
                                    id="nbrGameYears">
                                    {this.gameYearsOptions}
                                </select>
                            </div>                     
                        </span>
                    :
                        <span>
                            <div className="row w-100 mt-2 mx-2">
                                <div className="col-sm-6">
                                    <b>ChangeType:</b>
                                </div>  
                                <div className="col-sm-6 text-right"> 
                                    {this.changeText}
                                </div>   
                            </div>
                            <div className="row w-100 mt-2 mx-2">
                                <div className="col-sm-6">
                                    <b>Nbr of Months Per Turn:</b> 
                                </div>
                                <div className="col-sm-6 text-right">
                                    {this.monthsPerTurn}
                                </div>
                            </div>
                            <div className="row w-100 mt-2 mx-2">
                                <div className="col-sm-6">
                                    <b>Nbr of Years in the Game:</b>
                                </div>
                                <div className="col-sm-6 text-right">
                                    {this.gameNbrYears}
                                </div>
                            </div>
                           
                        </span>
                }
                </span>
                </span>
        </Modal.Body>
        <Modal.Footer className="backgroundTopBottom">
            <div className={this.messageClass}>{this.message}</div>
            {this.state.step === "confirm" ?
                <span>
                    <Button className="mr-2" variant="secondary"  onClick={() => this.saveTimeWizard()} >
                        Confirm Change
                    </Button>
                    <Button className="mr-2" variant="secondary"  onClick={() => this.resetFormValues()} >
                        Reset/Clear
                    </Button>
                </span>
            :
                <span>
                    <Button className="mr-2" variant="secondary"  onClick={() => this.checkRevisedValues()} >
                        Submit
                    </Button>
                </span>
            }
            <Button variant="primary"  onClick={() => this.props.onClose("none")}>
                     Close
            </Button>
           
        </Modal.Footer>
        </Modal>
        </>
    }

checkRevisedValues(){
    this.changeType = document.getElementById("changeType").value;
    this.monthsPerTurn = document.getElementById("monthsPerTurn").value;
    this.gameNbrYears = document.getElementById("nbrGameYears").value;
    this.editInputValues();

    if (this.errorFound === true){
        this.messageClass = "text-danger font-weight-bold";
        this.setState({step: "display"});
    }else{
        this.messageClass = "";
        if (this.monthsPerTurn !== this.props.nbrMonthsPerTurn ||
            this.gameNbrYears !== this.props.nbrGameYears){
                this.changeMade = true;
        }else{
                this.changeMade = false;
        }
        if (this.changeMade === true){
            this.setState({step: "confirm"})
        }else{
            this.props.onClose();
        }
    }
}

resetFormValues(){
    this.setState({step: "display"});
    this.message="";
}

editInputValues(){
    this.message = "";
    this.errorFound = false;
    this.changeTypeClass = "";
    this.monthsPerTurnClass = "";
    this.nbrGameYearsClass = "";
    if (this.changeType === "none"){
        this.message = "You must choose a Change Type."
        this.changeTypeClass = "text-danger"
        this.errorFound = true;
    }
    switch (this.changeType){
        case "restore":
            this.defaultValue = "1";
            this.monthsPerTurn = this.defaultMonthsPerTurn;
            this.gameNbrYears = this.defaultGameNbrYears;
            this.changeText = "Change to Game Defaults";
            break;
        case "change": 
            if (this.monthsPerTurn === "none" &&  this.gameNbrYears === "none"){
                 this.monthsPerTurnClass = "text-danger";
                 this.gameNbrYearsClass = "text-danger";
                 this.message = "The change option is set to 'change', you must " +
                    "change either the Months Per Turn or the Game Number of Years " +
                    "values."
                this.errorFound = true;
            }else{
                this.changeText = "Change Requested"
                if (this.monthsPerTurn === "none"){
                    this.monthsPerTurn = this.props.nbrMonthsPerTurn;
                }
                if (this.gameNbrYears === "none"){
                    this.gameNbrYears = this.props.nbrGameYears;
                }
             }
             break;
        default:
            break;
    }
}
    
saveTimeWizard(){
    if (this.changeType !== "noChange"){
        this.updateUnlockedFeatures();
        this.determineTimeWizardInfo();
        this.props.onClose("complete");
    }else{
        this.props.onClose("back");
    }
    
}

updateUnlockedFeatures(){  
    let index = this.gameInfo.gameData.unlockedFeatures.findIndex(elem => elem.featureName === 'timeWizard');
    let unlockedFeaturesObj = this.gameInfo.gameData.unlockedFeatures[index];
    this.activeInactive = "inactive";
    if (this.changeType === "change"){
          this.activeInactive = "active";
    }
    unlockedFeaturesObj.setup = this.activeInactive;

    let iconTable = this.gameInfo.gameData.iconTable;
    let startIdx = iconTable.length - 1;
    for (let i=startIdx; i>=0; i--){
        if (iconTable[i].iconName == "timeWizardUnlocked") {
           iconTable.splice(i,1);
           break;
        }
    }
 
    this.gameInfo.gameData.unlockedFeatures[index] = unlockedFeaturesObj;
    this.props.setGameInfo(this.gameInfo.gameData, 'gameData');
    this.bodyObj = {
        "currentCash": this.gameInfo.gameData.currentCash,
        "unlockedFeatures": this.gameInfo.gameData.unlockedFeatures,
        "iconTable": iconTable
    };
    const funcName = "updateUnlockedFeatures";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,
       {method:'PUT', headers: this.state.headers,
       body: JSON.stringify(this.bodyObj)})
       .then(response => {
        if (response.ok){
            response.json().then(data => {
            const output = JSON.parse(data);
            const statusCode = output.statusCode;
            if (statusCode == 200){
                 // "update of unlocked features was successful";
            }else{
                const errorObj={function: funcName, subProcess: this.state.subProcess, 
                    status: statusCode, message: output.body.message, errorObject: this.bodyObj };
                this.props.processError(errorObj);
            }
        });
        }
    });
}

determineTimeWizardInfo(){
    if (this.gameInfo.timeWizardInfo.gameID == undefined){
        this.newTimeWizardEntry = true;
    }else{
        this.newTimeWizardEntry = false;
    }
    let dateTime = getCurrentDateTime();
    let timeWizardObj = {};
    if (this.newTimeWizardEntry == true){
        timeWizardObj = findDataObject("timeWizardInfo").timeWizardInfo;
        timeWizardObj.gameID = this.gameInfo.gameData._id;
        timeWizardObj.dateAdded = dateTime.date;
    }else{
        timeWizardObj = this.gameInfo.timeWizardInfo;
    }
    timeWizardObj.status = this.activeInactive;
    timeWizardObj.gameDate = this.gameInfo.gameData.currentGameDate;
    timeWizardObj.nbrGameMonths = this.gameInfo.gameData.gameNbrMonths;
    timeWizardObj.nbrMonthsPerTurn = this.monthsPerTurn;
    timeWizardObj.nbrGameYears = this.gameNbrYears;
    timeWizardObj.prevGameYears = this.props.nbrGameYears;
    timeWizardObj.dateUpdated = dateTime.date;
    this.setBodyObj(timeWizardObj);
    this.updateTimeWizardInfo();
    if (+this.gameNbrYears !== +this.props.nbrGameYears){
        this.updateGameNbrMonths();
        const totalNbrGameMonths = this.gameNbrYears * 12;
        this.props.resetTotalNbrGameMonths(totalNbrGameMonths);
    }
}

setBodyObj(timeWizardObj){
    this.timeWizardObj = {
        "gameID": timeWizardObj.gameID,
        "status": timeWizardObj.status,
        "level": this.props.level,
        "monthsPerTurn": timeWizardObj.nbrMonthsPerTurn,
        "gameNbrYears": timeWizardObj.nbrGameYears,
        "gameDate": timeWizardObj.gameDate,
        "nbrGameMonths": timeWizardObj.nbrGameMonths,
        "prevGameYears": timeWizardObj.prevGameYears,
        "dateAdded": timeWizardObj.dateAdded,
        "dateUpdated": timeWizardObj.dateUpdated,
    };

    this.gameInfo.timeWizardInfo = this.timeWizardObj;
    this.props.setGameInfo(this.gameInfo.timeWizardInfo, "timeWizardInfo");
}

updateTimeWizardInfo(){
    const funcName = "updateTimeWizard";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,  
        {method:'PUT', headers: this.state.headers, 
        body: JSON.stringify(this.timeWizardObj)})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode == 200){
                     // "update of credit card Info was successful";
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message, errorObject: this.creditCardObj};
                    this.props.processError(errorObj);
                }
            });
            }
        });
}

updateGameNbrMonths(){
    const funcName = "setGameNbrMonths";
    //  calculate the number of months that have been played using current game years played
    let nbrMonthsPlayed = (+this.props.nbrGameYears * 12).toFixed(0) - 
        +this.gameInfo.gameData.gameNbrMonths;
    let gameNbrMonths = 0;
    if (this.activeInactive === "active"){
        gameNbrMonths = (+this.gameNbrYears * 12) - +nbrMonthsPlayed;
    }else{
        gameNbrMonths = (+this.defaultGameNbrYears * 12) - +nbrMonthsPlayed;
    }
    this.gameInfo.gameData.gameNbrMonths = gameNbrMonths;
    this.props.setGameInfo(this.gameInfo.gameData, "gameData");
    const urlParm = "?gameNbrMonths=" + gameNbrMonths;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,  
        {method:'PUT', headers: this.state.headers, 
        body: JSON.stringify(this.timeWizardObj)})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode == 200){
                     // "update of number of game months was successful";
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message, errorObject: this.creditCardObj};
                    this.props.processError(errorObj);
                }
            });
            }
        });
}
    
}

export default SetupTimeWizardModal;