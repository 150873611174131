import React from 'react';
import { withRouter } from "react-router";
import LinkCard from '../components/shared-components/link_card';
import { setHeadersForFetch } from '../objects/CommonUse/set_headers_for_fetch';
import buildUrl from '../objects/CommonUse/build_URL';
import Config from '../config';
import WaitTimeSpinner from '../components/shared-components/wait_time_spinner';
import AdminReportModal from '../components/admin/admin-report-modal';

class Homepage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            configurationAvailable: null,
            largeSize: window.matchMedia("(min-width: 1150px)").matches, 
            mediumSize: window.matchMedia("(min-width: 840px").matches,
            smallSize: window.matchMedia("(min-width: 800px").matches,
            environment: Config.dataURL.currentEnv,
            adminModalOpen: true,
        };
      
    }

    setScreenSizeFormat(){
        this.className = " input-page-base-card";
        this.textClass = "mx-4 mt-4 homepage-text decorative";
        this.imgClass = "mt-5 ml-3";
        if (this.state.largeSize == true){
            this.linkSize = "large";
            this.screenSize = "wide";
            this.className = "col-sm-4 mb-5";
         }else{
            if (this.state.mediumSize == true){
                this.linkSize = "mediumLarge";
                this.className = "col-sm-4 mt-4 mb-4 ";
                this.screenSize = "medium";
                this.textClass = this.textClass + " medium-large-text";
                this.imgClass = this.imgClass + " medium_size_img";
            }else{
                this.linkSize = "medium";
                this.className = "col-sm-4 mt-4 mb-4 ";
                this.screenSize = "narrow";
                this.textClass = this.textClass + " medium-text";
                this.imgClass = this.imgClass + " small_size_img";
            }
            if (this.state.smallSize == false){
                this.linkSize = "tooSmall";
            }
        }
        console.log("screenSize is: ", this.screenSize);
        sessionStorage.setItem("GameScreenSize", this.screenSize);
    }
  
    render() {
        this.setScreenSizeFormat();
        console.log('config available?: ', this.state.configurationAvailable);
        if (this.state.configurationAvailable == null  && this.props.userLoggedIn === true){
             this.buttonsDisabled = true;
        }else{
             this.buttonsDisabled = false;
        }
        this.role = sessionStorage.getItem("userRole");
       
    return <>
        <span className="container m-0 p-0">
                {this.buttonsDisabled ?
                    <div className="text-center">
                        <WaitTimeSpinner/>
                    </div>
                :
                <span className="row w-100 mx-0">
                    {this.role === "admin"  && this.state.adminModalOpen ?
                        <AdminReportModal
                            show={this.state.adminModalOpen}
                            onClose={() => this.closeAdminReportModal()}
                        />
                    :
                        null
                    }
                    <div className="col-sm-4">
                        <img className={this.imgClass} src={'./assets/sidebar_icon.png'} alt="Site Icon"/>
                        <div className={this.textClass}>
                        This game teaches essential money management skills. It is self-paced, 
                        allowing students to learn at their own level, whether they're grasping basic concepts 
                        or exploring more advanced topics. Click on the "About" square to learn more.
                        </div>
                    </div>
                    <div className={this.className}>
                        {this.state.configurationAvailable === true ? 
                            <LinkCard name="Game" imgSrc='./assets/bg_startGame.png' destination="game-board" 
                                allowed={this.props.userLoggedIn} inSize={this.linkSize}
                                openLoginMenu={() => this.props.openLoginMenu()} />
                        :
                            <LinkCard name="Game" imgSrc='./assets/bg_startGame.png' destination="skills-interests" 
                                allowed={this.props.userLoggedIn} inSize={this.linkSize}
                                openLoginMenu={() => this.props.openLoginMenu()}/>
                        }   
                            
                        <LinkCard name="For Parents/Teachers" imgSrc='./assets/bg_forParents.png' 
                            destination="for-parents-main"
                            allowed={this.props.userLoggedIn} inSize={this.linkSize}
                            openLoginMenu={() => this.props.openLoginMenu()}/>
                    
                    </div>
                    <div className={this.className}>
                        <LinkCard name="Extra Credit" imgSrc='./assets/bg_extraCredit.png' 
                            destination="extra-credit-main" 
                            allowed={this.props.userLoggedIn} inSize={this.linkSize}
                            openLoginMenu={() => this.props.openLoginMenu()}/>
                        <LinkCard name="About" imgSrc='./assets/bg_about.png' destination="about-general"
                            inSize={this.linkSize}/>
                    </div>
                </span>
                }
            </span>
        </>
    }
    
    componentDidMount(){
        this.linkSize = "";
        this.authErrorCount = 0;
        this.configurationAvailable();
        this.setScreenSizeFormat();
       
        const handler = e => this.setState({largeSize: e.matches});
        window.matchMedia("(min-width: 1200px)").addEventListener('change', handler);
        const handler2 = e => this.setState({mediumSize: e.matches});
        window.matchMedia("(min-width: 840px)").addEventListener('change', handler2);
        const handler3 = e => this.setState({smallSize: e.matches});
        window.matchMedia("(min-width: 800px)").addEventListener('change', handler3);
    }

    componentDidUpdate()
    {
        if ((!this.state.configurationAvailable && this.state.configurationAvailable != false) ||
            (this.username != this.props.username))
        {
            this.authErrorCount = 0;
            this.configurationAvailable();
        }
    }
    
    componentWillUnmount(){
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }

    closeAdminReportModal(){
        this.setState({adminModalOpen: false});
    }

    async configurationAvailable(){
        if (this.props.userLoggedIn === false)
        {
            return;
        }
        let headers = setHeadersForFetch();
        this.username = sessionStorage.getItem("username");
        const funcName = "loadGame";
        const urlParm = "?mode=validateAvailable";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,  
            {method:'GET',
            headers: headers
            })
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                        const output = JSON.parse(data);
                        const statusCode = output.statusCode;
                        if (statusCode == 200 || statusCode == 250){
                            if (output.body.configAvailable === true)
                                {   
                                    this.setState({configurationAvailable: true});
                                    sessionStorage.setItem("gameID", output.body.gameID);
                                } else {
                                    this.setState({configurationAvailable: false});
                                }
                        }else{
                            if (statusCode == 403 && this.authErrorCount <= 2){
                                this.authErrorCount = +this.authErrorCount + 1;
                                console.log("authentication error in loadGame - authErrorCount is: ", this.authErrorCount);
                            //    alert ("auth error in loadGame - attempting recovery");
                                this.configurationAvailable();
                            }else{
                                const parmObj = {username: this.username, headers: headers}
                                const errorObj={function: funcName, subProcess: "", 
                                    status: statusCode, message: output.body.message, errorObject: parmObj};
                                this.processErrorReturn(errorObj);
                            }
                        }
                    });
                };
            });
        }

    
processErrorReturn(errorObj){
    this.addRecordToLogfile(errorObj);
    if (errorObj.silentAlert == undefined || errorObj.silentAlert == false){
        alert ("Uh oh!  Something unexpected has occurred " +
            "error has been logged and will be addressed. " +
            "For now click OK, then return to the process " +
            "where you were and try again.");
        this.props.history.push('/');
    }
}

addRecordToLogfile(errorObj){
    const headers = setHeadersForFetch();  
    if (errorObj.status == 250){
        errorObj.message = "No record found";
    }
    if (errorObj.errorObject == undefined){
        errorObj.errorObject = {};
    }
    const logFileBody =  {
        mainProcess: "login",
        subProcess: "homePage",
        function: errorObj.function,
        status: errorObj.status,
        message: errorObj.message,
        errorObj: errorObj.errorObject
    }
    console.log("logFileBody is: ", logFileBody)
    let funcName = "saveLogFile"; 
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm)
    fetch (uri,  
        {method: 'POST', headers: headers, body: JSON.stringify(logFileBody)}
        )
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        console.log("write to logfile was successful");
                    }else{
                        console.log("Response from save log file not valid!" +
                         " status is: ", output.statusCode,
                         " message is: ", output.body);
                        alert ("Response from save log file not valid!");
                       
                    }
            });
            this.props.history.push('/');
        }
    });
  
}

}
export default withRouter(Homepage);